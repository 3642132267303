const hints = {
    checkPortfolio: "Check patient's medical records",
    check5R: 'Check 5R-Rules',
    handDisinfection: 'Hand disinfection',
    deskDisinfection: 'Desk disinfection',
    wearGloves: 'Wear gloves',
    takeDisinfectionCloth: 'Take disinfection clothe',
    takeOffGloves: 'Take off the gloves',
    prepareInjection: 'Prepare injection',
    takeInfusionSet: 'Take infusion set',
    checkInfusionSet: 'Check infusion set',
    unpackInfusionSet: 'Unpack the infusion set',
    closeRoller: 'Close the roller',
    takeOffInfusionSetCap: 'Take off the cap',
    takeDrug: 'Take infusion bottle',
    checkDrug: 'Check infusion bottle',
    takeOffBottleCap: 'Take off the cap',
    pierceInfusionSet: 'Pierce infusion set into bottle',
    hang: 'Hang the bottle of drug up',
    squeezeChamber: 'Squeeze the drip Chamber',
    openRoller: 'Open the roller',
    fixTube: 'Fix the tube',
    takeNameLabel: 'Take one name label',
    fillNameLabel: 'Fill the name label out',
    pasteNameLabel: 'Paste name label',
    wellDone: 'Well done, you have finish the exercise'
};

export default hints;