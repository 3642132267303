export const portfolio = {
    position: {
        ON_TABLE: 'ON_TABLE',
        IN_HAND: 'IN_HAND',
    },
};

export const bottle = {
    position: {
        IN_CUPBOARD: 'IN_CUPBOARD',
        IN_HAND: 'IN_HAND',
        ON_TABLE: 'ON_TABLE',
        HANGED: 'HANGED'
    }
};

export const infusionSet = {
    position: {
        IN_DRAWER: 'IN_DRAWER',
        IN_HAND: 'IN_HAND',
        ON_TABLE: 'ON_TABLE',
        IN_BOTTLE: 'IN_BOTTLE'
    }
};

export const nameLabel = {
    position: {
        IN_BOX: 'IN_BOX',
        IN_HAND: 'IN_HAND',
        ON_BOTTLE: 'ON_BOTTLE',
    }
};

export const supportedController = {
    geaVRController: 'Gear VR Controller',
    ViveController: 'OpenVR Gamepad'
};
